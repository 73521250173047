import moment from 'moment';
import Reevalidate from 'ree-validate';

export const gradeStatus = ['NG', 'INC', 'N', 'I', 'IN', 'D', 'R', 'DR'];

export const __text = (value) => {
  if (typeof value !== 'string') {
    return value;
  }

  return value
    .split(' ')
    .filter((val) => val !== '')
    .join(' ');
};
export const Revalidate = (values) => {
  const validator = new Reevalidate(values);

  const dictionary = {
    messages: {
      required: () => 'This field is required',
    },
  };

  validator.localize('en', dictionary);
  return validator;
};

export const systemRoles = {
  administrator: 'Administrator',
  school_admin: 'School Administrator',
  full_time: 'Full Time Instructor',
  part_time: 'Part Time Instructor',
  student: 'Student',
  cashier: 'Cashier',
  registrar: 'Registrar',
  parent: 'Parent',
  dean: 'Dean',
  principal: 'Principal',
  sss_coordinator: 'SSS Coordinator',
  sao_coordinator: 'SAO Coordinator',
  guidance_counselor: 'Guidance Counselor',
  librarian: 'Librarian',
  hs_teacher: 'HS Teacher',
  guard: 'Guard',
};
// check if authenticated
export const isAuth = () => {
  return !!localStorage.getItem('accessToken');
};

export const shortYear = (year) => {
  if (!year) {
    return '';
  }

  return year
    .split('-')
    .map((yr) => yr.slice(2))
    .join('-');
};

export const handleResponseError = (err) => {
  const messages = [];
  if (
    err &&
    err.response &&
    err.response.data &&
    err.response.data &&
    err.response.data.errors &&
    Object.keys(err.response.data.errors).length > 0
  ) {
    for (const key in err.response.data.errors) {
      const errors = err.response.data.errors;
      if (errors[key][0]) {
        messages.push(errors[key][0]);
      }
    }

    return messages.join(' \n');
  }

  return (err && err.response && err.response.data && err.response.data.message) || err.message;
};

export const excludedUser = (userRoles, roles) => {
  if (!roles && !roles.length) {
    return true;
  }

  let excluded = true;
  userRoles.forEach((role) => {
    if (roles.includes(role)) {
      excluded = false;
    }
  });

  return excluded;
};

export const studentStatus = [
  {
    value: '1',
    label: 'Regular',
  },
  {
    value: '0',
    label: 'Irregular',
  },
];

export const checkPermissions = (path, user) => {
  const currentPath = path
    .split('/')
    .map((pathInfo) => (pathInfo.includes(':') ? ':params' : pathInfo))
    .join('/');

  if (user && user.roles && user.roles.includes(systemRoles.administrator)) {
    if (currentPath.includes('/students')) {
      return true;
    }

    return !currentPath.includes('/student') && !currentPath.includes('/instructor');
  }

  return (
    (user && user.role_permissions && user.role_permissions.url.includes(currentPath)) || false
  );
};

export const checkAllowedUser = (userRoles, allowedRoles = []) => {
  if (allowedRoles.includes('*')) {
    return true;
  }

  let isAllowed = false;
  userRoles.forEach((role) => {
    if (allowedRoles.includes(role)) {
      isAllowed = true;
    }
  });

  return isAllowed;
};

// webcam image to blob
export const b64toBlob = (b64Data, contentType, sliceSize) => {
  contentType = contentType || '';
  sliceSize = sliceSize || 512;

  var byteCharacters = atob(b64Data);
  var byteArrays = [];

  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    var slice = byteCharacters.slice(offset, offset + sliceSize);

    var byteNumbers = new Array(slice.length);
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    var byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  var blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const schoolYearOptions = () => {
  const options = [];
  for (let index = parseInt(moment().format('YYYY')) + 2; index > 2020; index--) {
    options.push(`${index - 1}-${index}`);
  }
  return options;
};

export const semesterOptions = (prefix = false) => {
  const list = [
    {
      id: 1,
      label: 'First',
    },
    {
      id: 2,
      label: 'Second',
    },
    {
      id: 3,
      label: 'Summer',
    },
  ];

  if (prefix) {
    return list.map((sem) => (sem.id !== 3 ? { ...sem, label: `${sem.label} Semester` } : sem));
  }

  return list;
};

export const genderOptions = ['Male', 'Female'];

export const civilStatusOptions = ['Single', 'Married', 'Widow', 'Widower', 'Common Law'];

export const enrolleeTypeOptions = [
  {
    value: 'old',
    label: 'Old Student',
  },
  {
    value: 'new',
    label: 'New Student',
  },
  {
    value: 'returnee',
    label: 'Returnee',
  },
  {
    value: 'transferee',
    label: 'Transferee',
  },
];

export const strandOptions = ['HUMSS', 'ICT', 'HE', 'ABM'];

export const getEnrolleeType = (value) => {
  switch (value) {
    case 'new':
      return 'New Student';
    case 'old':
      return 'Old Student';
    case 'returnee':
      return 'Returnee';
    case 'transferee':
      return 'Transferee';
    default:
      return value;
  }
};

export const renderAddress = (info = {}) => {
  const keys = ['block', 'street', 'barangay', 'municipality', 'zipcode'];
  const address = [];
  for (const key in keys) {
    if (info[key]) {
      address.push(info[key]);
    }
  }
  return address.join(' ');
};

export const levelOptions = () => {
  return [
    {
      id: 1,
      label: 'First Year',
    },
    {
      id: 2,
      label: 'Second Year',
    },
    {
      id: 3,
      label: 'Third Year',
    },
    {
      id: 4,
      label: 'Fourth Year',
    },
  ];
};

export const getLevel = (level) => {
  if (!level) {
    return '';
  }

  const levels = ['First Year', 'Second Year', 'Third Year', 'Fourth Year'];
  return levels[parseInt(level) - 1];
};

export const getSemester = (sem, suffix = false, shortHand = false) => {
  if (!sem) {
    return '';
  }

  if (shortHand) {
    const list = ['1st', '2nd', 'Summer'];
    return suffix ? list[parseInt(sem) - 1] + ' Sem' : list[parseInt(sem) - 1];
  }

  const semesters = ['First', 'Second', 'Summer'];
  return suffix ? semesters[parseInt(sem) - 1] + ' Semester' : semesters[parseInt(sem) - 1];
};

export const toMoneyFormat = (value, digit = 2) => {
  const newValue = (value && parseFloat(value)) || 0;
  const tmpValue = newValue.toFixed(digit);
  const loopValue = tmpValue.substring(0, tmpValue.indexOf('.'));
  const decimalValue = tmpValue.substring(tmpValue.indexOf('.'), tmpValue.length);

  let stringValue = '';

  const number = new Array(10).fill(0).map((num, index) => index.toString());

  let counter = 0;
  for (let index = loopValue.length - 1; index >= 0; index--) {
    if (counter % 3 === 0 && counter >= 3 && number.includes(loopValue[index])) {
      stringValue = `,${stringValue}`;
    }
    stringValue = `${loopValue[index]}${stringValue}`;
    counter += 1;
  }

  return '₱ ' + stringValue + decimalValue;
};

export const getValuesFromObject = (object, name) => {
  if (!name) {
    return '';
  }

  if (typeof object !== 'object') {
    return '';
  }

  const arrayName = name.split('.');

  let value = object;
  arrayName.forEach((key) => {
    if (value[key]) {
      value = value[key];
    } else {
      value = '';
    }
  });

  return value;
};

export const salutationOptions = () => {
  return ['Mr.', 'Ms.', 'Mrs', 'Prof', 'Dr.', 'Fr.'];
};

export const getEnrollmentStatus = (status) => {
  const statuses = ['Rejected', 'Pending', 'For Enrollment', "Cashier's Hold", 'Enrolled'];

  return statuses[parseInt(status)];
};

export const enrollmentStatusOptions = () => {
  return [
    {
      name: 'Rejected',
      value: 0,
    },
    {
      name: 'Pending',
      value: 1,
    },
    {
      name: 'For Enrollment',
      value: 2,
    },
    {
      name: 'On Hold',
      value: 3,
    },
    {
      name: 'Enrolled',
      value: 4,
    },
  ];
};

export const gradYearsOptions = () => {
  const years = [];
  for (let index = moment().add(1, 'year').format('YYYY'); index >= 1980; index--) {
    years.push(index);
  }

  return years;
};

export const paymentRequirementType = ['Fixed', 'Percentage'];
